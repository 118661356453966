import React from 'react';
import "./Footer.css";
import data from '../../constants/data'

const Footer = () => {
    let date = new Date();
  return (
    <footer className='footer bg-black flex flex-center' id = "footer">
        <div className='container flex flex-center w-100'>
              <span className='footer-text '>&copy; {date.getFullYear().toString()} {data.ticker}. All rights reserved Team Viking.</span>
        </div>
    </footer>
  )
}

export default Footer
