import React from 'react';
import "./About.css";
import images from '../../constants/images';

const About = () => {
  return (
    <section className='about section-p bg-dark' id = "about">
        <div className='container'>
            <div className='about-content grid text-center'>
                <div className = "content-left">
                    <img src = {images.miss2} alt = "" />
                </div>
                <div className='content-right'>
                    <div className='section-t'>
                        <h6>Welcome to <br/>
                            DR EVIL <br/> Crew!</h6>
                    </div>
                    <p className='text'>
Greetings, future minions! Welcome to the sinister yet hilariously twisted world of Dr. Evil Meme. We are here to introduce you to the most maniacally fun meme coin on the Solana blockchain. Join our secret society of evil geniuses as we scheme to conquer the crypto universe with our diabolical humor and ruthless sarcasm. Prepare for world domination... in a very funny way. Mwahaha!
<br></br><br></br>
Attention, all aspiring villains! Our community is the malevolent core of our wicked project. Join us on our dark mission to spread laughter and wreak havoc across the crypto space. Dive into our fiendish discussions, share your nefarious ideas, and play your part in our evil master plan! Together, we will rule the meme world and have a few laughs while we’re at it. Mwahaha!




                    </p><br/>

                    <p className = "text"><h4>"Our Evil Plan", 
                        </h4>Phase 1:<br></br>
- Unleash our sinister website and social media channels.<br></br>
Phase 2:<br></br>
- Host a fiendish meme contest and evil community events.<br></br>
Phase 3:<br></br>
- Open our villainous merchandise store.<br></br>
Phase 4:<br></br>
- Develop cunning utility features. Expand our malevolent community outreach.<br></br>
Phase 5:<br></br>
- Execute a global marketing blitz and take over the world.</p>    
                </div>
            </div>
        </div>
    </section>
  )
}

export default About